import React from "react"
import Layout from "../../components/_layout"
import { Box, Flex, Text } from "rebass"

const PrivacyPolicy = () => {
  const TableEntries = [
    {
      title: "Di profilazione (proprietario)",
      items: [
        {
          cookie: "__spinup_uid",
          vendor: "Semplice Media",
          duration: "2 anni",
          description:
            "Questo cookie viene rilasciato durante l’atterraggio all’interno della pagina web. è necessario per poter riconoscere gli utenti univocamente tracciandone il loro percorso durante la navigazione.",
        },
      ],
    },
    {
      title: "Di profilazione (terze parti)",
      items: [
        {
          cookie: "_fbp",
          vendor: "Facebook",
          duration: "90 giorni",
          description:
            "Cookie rilasciato dallo script “Pixel” di Facebook. Questo cookie ha una durata di 90 giorni, viene gestito e rinnovato da Facebook ed è utilizzato per distinguere univocamente gli utenti che atterrano all’interno della pagina web.",
        },
        {
          cookie: "_fbc",
          vendor: "Facebook",
          duration: "90 giorni",
          description:
            "Cookie rilasciato dallo script “Pixel” di Facebook. Questo cookie è rilasciato solo nel caso in cui l’utente ha navigato all’interno della pagina web attraverso un link all’interno della piattaforma Facebook.",
        },
        {
          cookie: "_gcl_au",
          vendor: "Google Adsense",
          duration: "90 giorni",
          description:
            "Cookie rilasciato da Google Analytics / Tag Manager. Questo cookie viene utilizzato per il conversion tracking delle campagne.",
        },
        {
          cookie: "auid",
          vendor: "Outbrain",
          duration: "90 giorni",
          description:
            "Contiene l’ID pubblicità dell’utente nei dispositivi mobili. Utilizzato per monitorare le azioni dell’utente, come i clic sui consigli.",
        },
        {
          cookie: "outbrain_click_id",
          vendor: "Outbrain",
          duration: "1 giorno",
          description:
            "Cookie utilizzato per il conversion tracking id Outbrain.",
        },
        {
          cookie: "outbrain_cid_fetch",
          vendor: "Outbrain",
          duration: "60 minuti",
          description:
            "Cookie utilizzato per il conversion tracking id Outbrain.",
        },
        {
          cookie: "obuid",
          vendor: "Outbrain",
          duration: "3 mesi",
          description:
            "Contiene l’ID dell’utente anonimo. Utilizzato per tenere traccia delle azioni dell’utente, come i clic sui consigli.",
        },
        {
          cookie: "recs-{}",
          vendor: "Outbrain",
          duration: "1 minuto",
          description:
            "Memorizza i documenti che stiamo consigliando in modo da non mostrare la stessa raccomandazione sulla stessa pagina.",
        },
        {
          cookie: "Pixel cookieless (senza altra denominazione)",
          vendor: "Across",
          duration: "Illimitata",
          description:
            "Questo pixel raccoglie l’indirizzo IP anonimizzato e l’user agent del visitatore e viene utilizzato per tenere traccia dei visitatori che visitano un determinata pagina web.",
        },
      ],
    },
    {
      title: "Statistici",
      items: [
        {
          cookie: "_ga",
          vendor: "Google Analytics",
          duration: "2 anni",
          description:
            "Cookie rilasciato da Google Analytics. Viene utilizzato per poter distinguere gli utenti univoci che atterrano sulla pagina web.",
        },
        {
          cookie: "_gid",
          vendor: "Google Analytics",
          duration: "24 ore",
          description:
            "Cookie rilasciato da Google Analytics. Viene utilizzato per poter distinguere gli utenti univoci che atterrano sulla pagina web.",
        },
        {
          cookie: "_gat",
          vendor: "Google Analytics",
          duration: "1 minuto",
          description:
            "Cookie rilasciato da Google Analytics. Viene utilizzato per effettuare un throttling sulle richieste HTTP verso Google Analytics.",
        },
        {
          cookie: "_gac_<property-id>",
          vendor: "Google Analytics",
          duration: "90 giorni",
          description:
            "Cookie rilasciato da Google Analytics. Contiene informazioni relative alle campagne Google Ads effettuate sulla pagina. Questo cookie non è sempre presente.",
        },
        {
          cookie: "_hjSessionUser{site_id}",
          vendor: "Hotjar",
          duration: "365 giorni",
          description:
            "Cookie rilasciato da Hotjar. Contiene informazioni relative all’ID utente unico, generato per poter distinguere gli utenti sulla pagina web.",
        },
        {
          cookie: "_hjSession{site_id}",
          vendor: "Hotjar",
          duration: "30 minuti",
          description:
            "Cookie rilasciato da Hotjar. Contiene informazioni relative alla sessione attuale dell’utente.",
        },
        {
          cookie: "_hjSessionTooLarge",
          vendor: "Hotjar",
          duration: "Sessione",
          description:
            "Cookie rilasciato da Hotjar solo nel momento in cui viene bloccato il salvataggio dei dati sessione.",
        },
        {
          cookie: "_hjSessionRejected",
          vendor: "Hotjar",
          duration: "Sessione",
          description:
            "Cookie rilasciato da Hotjar solo nel momento in cui la sessione viene rifiutata per overload del server.",
        },
        {
          cookie: "_hjSessionResumed",
          vendor: "Hotjar",
          duration: "Sessione",
          description:
            "Cookie rilasciato da Hotjar solo nel momento in cui la sessione viene ripristinata dopo un blocco di connessione.",
        },
        {
          cookie: "_hjid",
          vendor: "Hotjar",
          duration: "365 giorni",
          description:
            "Cookie Hotjar che viene rilasciato quando l’utente arriva per la prima volta su una pagina con lo script Hotjar. Viene utilizzato per mantenere l’ID utente Hotjar, univoco per quel sito sul browser.",
        },
        {
          cookie: "_hjRecordingLastActivity",
          vendor: "Hotjar",
          duration: "Sessione",
          description:
            "Quando viene eseguito lo script Hotjar, cerca di determinare il percorso del cookie pià¹ generico da utilizzare, invece del nome host della pagina. Questo viene fatto in modo che i cookie possano essere condivisi tra i sottodomini (ove applicabile). Per determinare cià², si tenta di memorizzare il cookie _hjTLDTest per diverse alternative di sottostringa dell’URL finchà© non fallisce. Dopo questo controllo, il cookie viene rimosso",
        },
        {
          cookie: "_hjTLDTest",
          vendor: "Hotjar",
          duration: "Sessione",
          description:
            "Cookie Hotjar rilasciato quando inizia la registrazione di un utente e quando i dati vengono inviati tramite WebSocket (l’utente esegue un’azione registrata da Hotjar).",
        },
        {
          cookie: "_hjUserAttributesHash",
          vendor: "Hotjar",
          duration: "Sessione",
          description:
            "Gli attributi utente inviati tramite l’API Hotjar Identify vengono memorizzati nella cache per la durata della sessione per sapere quando un attributo è cambiato e deve essere aggiornato.",
        },
        {
          cookie: "_hjCachedUserAttributes",
          vendor: "Hotjar",
          duration: "Sessione",
          description:
            "Questo cookie memorizza gli attributi utente che vengono inviati tramite l’API di identificazione di Hotjar, ogni volta che l’utente non è nel campione. Gli attributi raccolti verranno salvati sui server Hotjar solo se l’utente interagisce con uno strumento di feedback di Hotjar, ma il cookie verrà  utilizzato indipendentemente dalla presenza o meno di uno strumento di feedback.",
        },
        {
          cookie: "_hjLocalStorageTest",
          vendor: "Hotjar",
          duration: "< 100ms",
          description:
            "Questo cookie viene utilizzato per verificare se lo script di tracciamento di Hotjar puà² utilizzare il local storage. Se possibile, in questo cookie viene impostato il valore 1. I dati archiviati in_hjLocalStorageTest non hanno una scadenza, ma vengono eliminati quasi immediatamente dopo essere stati creati.",
        },
        {
          cookie: "_hjIncludedInPageviewSample",
          vendor: "Hotjar",
          duration: "30 minuti",
          description:
            "Questo cookie è impostato per far sapere a Hotjar se quell’utente è incluso nel campionamento dei dati definito dal limite di visualizzazioni di pagina del tuo sito.",
        },
        {
          cookie: "_hjIncludedInSessionSample",
          vendor: "Hotjar",
          duration: "30 minuti",
          description:
            "Questo cookie è impostato per far sapere a Hotjar se quell’utente è incluso nel campionamento dei dati definito dal limite di sessione giornaliera del tuo sito.",
        },
        {
          cookie: "_hjAbsoluteSessionInProgress",
          vendor: "Hotjar",
          duration: "30 minuti",
          description:
            "Questo cookie viene utilizzato per rilevare la prima sessione di visualizzazione di pagina di un utente. Questo è un flag True/False impostato dal cookie.",
        },
        {
          cookie: "_hjFirstSeen",
          vendor: "Hotjar",
          duration: "Sessione",
          description:
            "Questo è impostato per identificare la prima sessione di un nuovo utente. Memorizza un valore vero/falso, che indica se questa è stata la prima volta che Hotjar ha visto questo utente. Viene utilizzato dai filtri di registrazione per identificare nuove sessioni utente.",
        },
        {
          cookie: "_hjViewportId",
          vendor: "Hotjar",
          duration: "Sessione",
          description:
            "Questo cookie contiene informazioni sulle dimensioni del display dell’utente.",
        },
        {
          cookie: "_hjRecordingEnabled",
          vendor: "Hotjar",
          duration: "Sessione",
          description:
            "Questo cookie viene aggiunto all’avvio di una registrazione e viene letto quando il modulo di registrazione viene inizializzato per vedere se l’utente è già  in una registrazione in una particolare sessione.",
        },
        {
          cookie: "enc_aff_session_",
          vendor: "HasOffers",
          duration: "1 mese",
          description:
            "Identificativo sessione. Questo cookie viene utilizzato per calcolare il numero delle conversioni. Nessun dato sensibile viene salvato o inviato.",
        },
        {
          cookie: "ho_mob",
          vendor: "HasOffers",
          duration: "3 anni",
          description:
            "Identificativo utente. Questo cookie viene utilizzato per calcolare il numero delle conversioni. Nessun dato sensibile viene salvato o inviato.",
        },
      ],
    },
  ]

  return (
    <Layout forceOpaqueHeader>
      <Flex
        sx={{ paddingX: [5, 10] }}
        paddingY={"10rem"}
        justifyContent={"center"}
      >
        <Box sx={{ flexGrow: 1 }} maxWidth={1280}>
          <Text mt={[3, 4]} fontSize={["34px", "46px"]} fontWeight="700">
            Privacy Policy
            <br /> Semplice Media S.r.l.
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            Il presente documento è redatto da Semplice Media S.r.l. (di seguito
            “Semplice Media”), in qualità di titolare del trattamento dei dati
            personali derivanti dalla navigazione all’interno del presente sito
            o pagina web (di seguito il “Sito”). Si tratta di un’informativa
            resa in conformità alla vigente normativa in materia dei dati
            personali nel quadro del Regolamento UE 2016/679.
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Titolare del trattamento
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            Titolare del Trattamento è SEMPLICE MEDIA S.R.L. a socio unico,
            P.IVA: 08542331213, società soggetta a Direzione e Coordinamento di
            Innova Semplice S.p.A.; sede legale e operativa in Napoli (NA), Via
            Medina n. 40 – 80133, indirizzo di posta elettronica{" "}
            <a href="mailto:info@semplicemedia.it.">info@semplicemedia.it.</a>
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            Il responsabile della protezione dei dati (DPO) è l’avv. Tino
            Crisafulli. Gli utenti possono anche contattare il Responsabile
            della protezione dei dati (DPO) di Semplice Media s.r.l. al seguente
            indirizzo mail:{" "}
            <a href="mailto:privacy@semplicemedia.it">
              privacy@semplicemedia.it.
            </a>
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Tipologia di dati e finalità di trattamento
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Categorie dei dati trattati
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Dati di navigazione
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            I sistemi informatici e le procedure software preposte al
            funzionamento del Sito acquisiscono, nel corso del loro normale
            esercizio, alcuni dati personali la cui trasmissione è implicita
            nell’uso dei protocolli di comunicazione di Internet. Si tratta di
            informazioni che non sono raccolte per essere associate a
            interessati identificati, ma che per loro stessa natura potrebbero,
            attraverso elaborazioni ed associazioni con dati detenuti da terzi,
            permettere di identificare gli utenti. In questa categoria di dati
            rientrano gli indirizzi IP o i nomi a dominio dei computer
            utilizzati dagli utenti che si connettono al sito, gli indirizzi in
            notazione URI (Uniform Resource Identifier) delle risorse richieste,
            l’orario della richiesta, il metodo utilizzato nel sottoporre la
            richiesta al server, la dimensione del file ottenuto in risposta, il
            codice numerico indicante lo stato della risposta data dal server
            (buon fine, errore, ecc.) ed altri parametri relativi al sistema
            operativo e all’ambiente informatico dell’utente. Questi dati
            vengono utilizzati al solo fine di ricavare informazioni statistiche
            anonime sull’uso del Sito e per controllarne il corretto
            funzionamento e vengono cancellati dopo l’elaborazione. I dati
            potrebbero essere anche utilizzati per l’accertamento di
            responsabilità in caso di ipotetici reati informatici ai danni del
            Sito. I dati raccolti vengono trattati da Semplice Media per
            navigare sul Sito che potrà contenere anche comunicazioni
            commerciali di terze parti, cookies tecnici per migliorare la
            navigazione sul Sito e cookies di profilazione o di targeting.
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Dati forniti volontariamente dagli utenti/visitatori
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            Qualora gli utenti/visitatori, collegandosi a questo sito, inviino
            propri dati personali per accedere a determinati servizi, ovvero per
            effettuare richieste in posta elettronica, ciò comporta
            l’acquisizione da parte di Semplice Media dell’indirizzo del
            mittente e/o di altri eventuali dati personali che verranno trattati
            esclusivamente per rispondere alla richiesta, ovvero per la
            fornitura del servizio. I dati personali forniti dagli
            utenti/visitatori verranno comunicati a terzi solo nel caso in cui
            la comunicazione sia necessaria per ottemperare alle richieste degli
            utenti/visitatori medesimi.
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Destinatari dei dati personali
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            I dati personali raccolti da Semplice Media tramite questo sito
            verranno condivisi soltanto dal personale interno (autorizzato al
            trattamento) e da collaboratori esterni che supportano Semplice
            Media nelle attività di marketing e profilazione.
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Trasferimento dei dati personali
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            I dati personali raccolti Semplice Media tramite questo sito non
            sono oggetto di trasferimento nei confronti di un paese terzo o nei
            confronti di un’organizzazione internazionale e l’esistenza.
            Semplice Media non effettua alcun trasferimento di dati; in
            particolare non trovano applicazione le norme sul trasferimento dei
            dati contenute all’art. 46 o 47, o all’articolo 49, secondo comma,
            del GDPR.
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Modalità di trattamento e comunicazione
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            Semplice Media tratta i dati personali raccolti con strumenti
            elettronici, e con strumenti automatizzati, per il tempo
            strettamente necessario a conseguire gli scopi per cui sono stati
            raccolti. I dati personali raccolti saranno trattati dal personale
            incaricato che abbia necessità di averne conoscenza in funzione
            della propria attività e da soggetti esterni autorizzati dal
            Titolare del Trattamento che potranno agire quali responsabili del
            trattamento, con o senza l’utilizzo di strumenti elettronici (tra i
            quali e-mail, fax, telefono, messaggistica istantanea quale whatsup,
            messenger, facebook, chatbot ed ogni altra tecnologia di
            comunicazione a distanza) secondo principi di correttezza, liceità,
            trasparenza in maniera tale da garantirne la sicurezza e la
            riservatezza. I dati potrebbero essere trasferiti verso un paese
            terzo sulla base delle decisioni di adeguatezza della Commissione
            Europea ovvero sulla base di adeguate garanzie previste dalla
            normativa vigente. I dati personali raccolti non saranno diffusi.
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Periodo di conservazione
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            I dati personali sono trattati con strumenti automatizzati per il
            tempo strettamente necessario a conseguire gli scopi per cui sono
            stati raccolti. Specifiche misure di sicurezza sono osservate per
            prevenire la perdita dei dati, usi illeciti o non corretti ed
            accessi non autorizzati. I dati sono conservati per il tempo
            strettamente necessario per il perseguimento delle finalità indicate
            nella presente informativa e saranno cancellati al termine di tale
            periodo, salvo che i dati stessi debbano essere conservati per
            obblighi di legge o per far valere un diritto in sede giudiziaria.
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Finalità di trattamento
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            Tramite il presente sito web, Semplice Media persegue le seguenti
            finalità di trattamento:
            <br />
            a) promuovere, pubblicizzare e divulgare i contenuti informativi
            pubblicati sul sito;
            <br />
            b) promuovere, pubblicizzare e divulgare i servizi offerti da
            Semplice Media;
            <br />
            c) promuovere, pubblicizzare e divulgare i contenuti formativi
            realizzati da Semplice Media;
            <br />
            d) svolgere attività di profilazione (cioè analizzare gli interessi
            ed i comportamenti dei visitatori di questo sito, per migliorare le
            campagne di marketing);
            <br />
            e) rispondere ad una richiesta di informazioni o ad una richiesta di
            assistenza;
            <br />
            f) svolgere attività di consulenza nei confronti dei soggetti
            interessati a tali servizi;
            <br />
            h) svolgere attività di promozione e vendita tramite l’utilizzo di
            “mailing list” e servizi di posta elettronica.
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Base giuridica del trattamento dei dati
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            Quando Semplice Media tratta i dati personali per le finalità
            indicate in questa Informativa, la stessa Semplice Media si basa su
            uno o più dei seguenti fondamenti giuridici: Consenso: Semplice
            Media utilizza i dati degli interessati solo se quest’ultimi hanno
            manifestato il consenso. In particolare Semplice Media tratterà i
            dati degli interessati per: a) rispondere ad una richiesta di
            assistenza; b) aggiornare l’interessato sui contenuti informativi;
            c) inviare all’interessato informazioni sui prodotti e servizi di
            Semplice Media; d) svolgere attività di profilazione (cioè
            analizzare gli interessi ed i comportamenti dei visitatori del sito
            e per migliorare le campagne di marketing). Ambito contrattuale:
            quando un interessato stipula un contratto con Semplice Media, i
            suoi dati personali verranno trattati per perseguire le finalità del
            servizio acquistato. Obblighi di legge: quando strettamente
            necessario, Semplice Media tratterà i dati personali
            dell’interessato per rispettare un obbligo legale, un ordine imposto
            dall’autorità di controllo, un accertamento fiscale vincolante.
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Legittimi interessi del Titolare del trattamento
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            Tramite questo sito Semplice Media tratta i dati personali degli
            interessati per finalità di marketing diretto. Se l’interessato ha
            già manifestato il consenso al trattamento dei dati, Semplice Media
            potrà inviare comunicazioni commerciali al fine di promuovere i
            prodotti e i servizi offerti. Ai sensi di quanto previsto dal
            considerando n. 47 del GDPR (Regolamento UE 679/2016) può essere
            considerato legittimo interesse trattare dati personali per finalità
            di marketing diretto. In particolare Semplice Media tratterà i dati
            personali degli interessati senza pregiudicare diritti o libertà
            fondamentali, tenuto conto delle ragionevoli aspettative che gli
            interessati nutrivano quando hanno manifestato il consenso al
            trattamento.
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Diritti degli interessati
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            Inviando una mail all’indirizzo{" "}
            <a href="mailto:hello@semplicemedia.it">hello@semplicemedia.it</a> o{" "}
            <a href="mailto:privacy@semplicemedia.it">
              privacy@semplicemedia.it
            </a>{" "}
            potranno essere esercitati tutti i diritti previsti dalla Normativa
            Privacy tra i quali i) accesso (per conoscere l’origine dei dati, le
            finalità e modalità di trattamento, gli estremi del Titolare, gli
            estremi di eventuali responsabili e soggetti cui i dati possono
            essere comunicati) ii) rettifica (per aggiornare e rettificare i
            dati) iii) cancellazione dei dati iv) limitazione (per limitare il
            trattamento) iv) revoca del consenso prestato e opposizione al
            trattamento. In caso di trattamento in violazione del Regolamento UE
            è possibile proporre reclamo al Garante per la protezione dei dati
            personale. Per conoscere i dati di contatto del Garante si prega di
            cliccare{" "}
            <a href="http://www.garanteprivacy.it/home/footer/contatti"> QUI</a>
            .
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Esistenza di un processo decisionale automatizzato
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            I dati personali personali raccolti dal Semplice Media tramite
            questo sito non sono soggetti ad un trattamento automatizzato.
            <br />
            La decisione sul trattamento dei dati è adottata dal Semplice Media.
            <br />
            Nel caso in cui i dati personali siano stati raccolti per finalità
            di marketing e profilazione, Semplice Media potrà utilizzare dei
            software che gestiscono in modo automatizzato l’invio di
            comunicazioni via email.
            <br />
            In quest’ultimo caso, Semplice Media può utilizzare dei processi
            decisionali automatizzati per valutare il comportamento e gli
            interessi dell’interessato in relazione ai contenuti inviati tramite
            email.
            <br />
            In particolare se l’interessato si è iscritto alla lista email di
            Semplice Media (prestando il suo consenso), Semplice Media potrà
            analizzare:
            <br />
            a) la “percentuale di apertura” delle email inviate (al fine di
            capire se c’è interesse sull’argomento trattato);
            <br />
            b) la “percentuale di click” ai link contenuti nelle email inviate
            (al fine di capire se c’è interesse ad approfondire un determinato
            tema).
            <br />
            Al fine di prevenire il rischio connesso al trattamento dei dati
            tramite un processo decisionale automatizzato, Semplice Media redige
            ogni anno il documento “DPIA” (Data Protection Impact Assessment).
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Fonte da cui hanno origine i dati
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            I dati personali trattati da Semplice Media hanno come fonte di
            origine il consenso volontario dell’interessato. Il consenso
            volontario dell’interessato può essere manifestato in modalità
            telematica (tramite questo sito) o in modalità cartacea, previa
            sottoscrizione di un modulo di autorizzazione al trattamento.
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Misure di sicurezza per la conservazione dei dati
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            Semplice Media osserva specifiche misure di sicurezza per prevenire
            la perdita dei dati, usi illeciti o non corretti ed accessi non
            autorizzati. Per garantire la conservazione dei dati in condizione
            di massima sicurezza, Semplice Media un software gestionale con
            autenticazione all’accesso. Tale sistema impedisce l’accesso a
            soggetti non autorizzati al trattamento ed impedisce la lettura dei
            dati raccolti.
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Facoltatività del conferimento dei dati
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            A parte quanto affermato per i dati di navigazione, gli
            utenti/visitatori di questo sito sono liberi di fornire i propri
            dati personali. Il loro mancato conferimento può comportare
            unicamente l’impossibilità di ottenere quanto richiesto.
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Luogo di trattamento dei dati
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            I trattamenti connessi ai servizi web di questo sito, hanno luogo
            sia presso la sede di Semplice Media, sia presso la sede aziendale
            e/o presso la sede della società di hosting e/o gestione del sito
            web e sono curati solo da personale tecnico dell’Ufficio incaricato
            del trattamento, oppure da eventuali incaricati di occasionali
            operazioni di manutenzione.
            <br /> Nessun dato derivante dal servizio web viene comunicato o
            diffuso.
            <br />I dati personali forniti dagli utenti che inoltrano richieste
            di invio di materiale informativo sono utilizzati al solo fine di
            eseguire il servizio o la prestazione richiesta e sono comunicati a
            terzi nel solo caso in cui ciò sia a tal fine necessario.
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Uso dei cookie
          </Text>
          <Text mt={[3, 4]} fontSize={["13px"]}>
            Il “Sito” utilizza i Cookie per rendere i propri servizi semplici e
            efficienti per l’utente che visiona le pagine web. Gli utenti che
            visionano il Sito, vedranno inserite delle quantità minime di
            informazioni nei dispositivi in uso, che siano computer e
            periferiche mobili, in piccoli file di testo denominati “cookie”
            salvati nelle directory utilizzate dal browser web dell’utente. Vi
            sono vari tipi di cookie, alcuni per rendere più efficace l’uso del
            Sito, altri per abilitare determinate funzionalità.
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Tipologie di Cookie:
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Cookie tecnici
          </Text>{" "}
          <Text mt={[3, 4]} fontSize={["13px"]}>
            Questa tipologia di cookie permette il corretto funzionamento di
            alcune sezioni del Sito. Sono di due categorie: persistenti e di
            sessione:
            <br />
            &bull; persistenti: una volta chiuso il browser non vengono
            distrutti ma rimangono fino ad una data di scadenza preimpostata
            <br />
            &bull; di sessione: vengono distrutti ogni volta che il browser
            viene chiuso
            <br />
            Questi cookie, inviati sempre dal nostro dominio, sono necessari a
            visualizzare correttamente il Sito e in relazione ai servizi tecnici
            offerti, verranno quindi sempre utilizzati e inviati, a meno che
            l’utenza non modifichi le impostazioni nel proprio browser
            (inficiando così la visualizzazione delle pagine del Sito).
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Cookie analitici
          </Text>{" "}
          <Text mt={[3, 4]} fontSize={["13px"]}>
            I cookie in questa categoria vengono utilizzati per raccogliere
            informazioni sull’uso del Sito. Il Titolare del Trattamento userà
            queste informazioni in merito ad analisi statistiche anonime al fine
            di migliorare l’utilizzo del Sito e per rendere i contenuti più
            interessanti e attinenti ai desideri dell’utenza. Questa tipologia
            di cookie raccoglie dati in forma anonima sull’attività dell’utente
            e su come è arrivato sul Sito. I cookie analitici sono inviati dal
            Sito stesso o da domini di terze parti.
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Cookie di analisi di servizi di terze parti
          </Text>{" "}
          <Text mt={[3, 4]} fontSize={["13px"]}>
            Questi cookie sono utilizzati al fine di raccogliere informazioni
            sull’uso del Sito da parte degli utenti in forma anonima quali:
            pagine visitate, tempo di permanenza, origini del traffico di
            provenienza, provenienza geografica, età, genere e interessi ai fini
            di campagne di marketing. Questi cookie sono inviati da domini di
            terze parti esterni al Sito.
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Cookie di analisi di servizi di terze parti
          </Text>{" "}
          <Text mt={[3, 4]} fontSize={["13px"]}>
            Questi cookie sono utilizzati al fine di raccogliere informazioni
            sull’uso del Sito da parte degli utenti in forma anonima quali:
            pagine visitate, tempo di permanenza, origini del traffico di
            provenienza, provenienza geografica, età, genere e interessi ai fini
            di campagne di marketing. Questi cookie sono inviati da domini di
            terze parti esterni al Sito.
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Cookie per integrare prodotti e funzioni di software di terze parti
          </Text>{" "}
          <Text mt={[3, 4]} fontSize={["13px"]}>
            Questa tipologia di cookie integra funzionalità sviluppate da terzi
            all’interno delle pagine del Sito come le icone e le preferenze
            espresse nei social network al fine di condivisione dei contenuti
            del sito o per l’uso di servizi software di terze parti (come i
            software per generare le mappe e ulteriori software che offrono
            servizi aggiuntivi). Questi cookie sono inviati da domini di terze
            parti e da siti partner che offrono le loro funzionalità tra le
            pagine del Sito.
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Cookies di profilazione e di targeting
          </Text>{" "}
          <Text mt={[3, 4]} fontSize={["13px"]}>
            Questa tipologia di cookie integra funzionalità sviluppate da terzi
            all’interno delle pagine del Sito come le icone e le preferenze
            espresse nei social network al fine di condivisione dei contenuti
            del sito o per l’uso di servizi software di terze parti (come i
            software per generare le mappe e ulteriori software che offrono
            servizi aggiuntivi). Questi cookie sono inviati da domini di terze
            parti e da siti partner che offrono le loro funzionalità tra le
            pagine del Sito.
            <br />
            <br />
            Semplice Media utilizza cookies promozionali esclusivamente in
            modalità anonima: la pubblicità è mirata ma non è identificabile chi
            sia l’utente. I cookies promozionali utilizzati inoltre sono di
            terzi e sono qui elencati con relativo link per richiederne la
            disattivazione:
            <br />
            <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
              <li style={{ wordWrap: "" }}>
                <a href="www.facebook.com/policy/cookies/">
                  www.facebook.com/policy/cookies/
                </a>
              </li>
              <li style={{ wordWrap: "" }}>
                <a href="tools.google.com/dlpage/gaoptout?hl=en">
                  tools.google.com/dlpage/gaoptout?hl=en
                </a>
              </li>
              <li style={{ wordWrap: "" }}>
                <a href="adssettings.google.com/authenticated#display_optout">
                  adssettings.google.com/authenticated#display_optout
                </a>
              </li>
              <li style={{ wordWrap: "" }}>
                <a href="policies.google.com/privacy">
                  policies.google.com/privacy
                </a>
              </li>
              <li style={{ wordWrap: "" }}>
                <a href="www.outbrain.com/legal/privacy#advertising_behavioral_targeting">
                  www.outbrain.com/legal/privacy#advertising_behavioral_targeting
                </a>
              </li>
              <li style={{ wordWrap: "" }}>
                <a href="newrelic.com/termsandconditions/privacy">
                  newrelic.com/termsandconditions/privacy
                </a>
              </li>
              <li style={{ wordWrap: "" }}>
                <a href="www.hotjar.com/legal/policies/privacy/">
                  www.hotjar.com/legal/policies/privacy/
                </a>
              </li>
              <li style={{ wordWrap: "" }}>
                <a href="www.tiktok.com/legal/cookie-policy?lang=it">
                  www.tiktok.com/legal/cookie-policy?lang=it
                </a>
              </li>
              <li style={{ wordWrap: "" }}>
                <a href="fomo.com/privacy">fomo.com/privacy</a>
              </li>
              <li style={{ wordWrap: "" }}>
                <a href="logrocket.com/privacy/">logrocket.com/privacy/</a>
              </li>
              <li style={{ wordWrap: "" }}>
                <a href="heap.io/privacy">heap.io/privacy</a>
              </li>
              <li style={{ wordWrap: "" }}>
                <a href="www.tune.com/resources/data-and-privacy/privacy-policies/">
                  www.tune.com/resources/data-and-privacy/privacy-policies/
                </a>
              </li>
            </ul>
            <br />
            <br />
            Il Titolare del Trattamento secondo la normativa vigente, non è
            tenuto a chiedere consenso per i cookie tecnici e di analytics, in
            quanto necessari a fornire i servizi richiesti. In ogni caso
            l’utente può impostare nel browser le proprie preferenze di privacy
            in modo da non memorizzare cookie, cancellarli dopo ogni visita o
            ogni volta che chiudi il browser, o anche accettare solo i cookie
            del sito o app e non quelli di terze parti. Se si sceglie di
            eliminare questi cookies tuttavia, l’accesso ad alcune funzionalità
            e aree del sito potrebbe essere peggiorato o limitato, e comunque
            non pienamente soddisfacente.
            <br />
            Maggiori informazioni possono essere richieste scrivendo al Titolare
            all’indirizzo mail:{" "}
            <a href="mailto:hello@semplicemedia.it"> hello@semplicemedia.it</a>
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Pixel di Semplice Media
          </Text>{" "}
          <Text mt={[3, 4]} fontSize={["13px"]}>
            Questo sito e le pagine web create da Semplice Media, utilizzano uno
            script di nome “Pixel di Semplice Media”. Utilizziamo questo
            strumento per la raccolta di dati statistici che ci consentono di
            misurare l’efficacia della pubblicità a pagamento. Grazie al Pixel
            di Semplice Media possiamo comprendere le azioni che le persone
            eseguono sul nostro sito Web e sulle nostre pagine web. I dati che
            raccogliamo vengono utilizzati per:
            <br />
            <br /> &bull;&nbsp; Assicurarci che le inserzioni vengano mostrate
            alle persone giuste;
            <br /> &bull;&nbsp; Creare gruppi di pubblico a cui destinare le
            inserzioni;
            <br /> &bull;&nbsp; Sfruttare gli ulteriori strumenti pubblicitari
            della piattaforma su cui facciamo pubblicità.
            <br />
            <br />
            Utilizziamo il Pixel di Semplice Media per effettuare attività di
            “remarketing” e attività di “retargeting”; grazie a queste attività
            utilizziamo i cookies senza identificarti. In questo modo i tuoi
            dati di navigazione non vengono collegati alla tua identità e ai
            tuoi dati personali. I dati raccolti grazie al Pixel di Semplice
            Media vengono utilizzati soltanto da noi e da professionisti esterni
            che ci supportano nelle attività di marketing e di pubblicità
            online.
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Software di analisi
          </Text>{" "}
          <Text mt={[3, 4]} fontSize={["13px"]}>
            Questo sito web, e tutte le pagine web create da Semplice Media (in
            cui è stato attivato il software di tracciamento), utilizzano un
            software di analisi che permette di registrare la sessione di
            navigazione dell’utente in forma anonima e senza il trattamento di
            dati personali. Utilizziamo questo strumento per analizzare il
            comportamento degli utenti mentre visitano le nostre pagine web e
            per migliorare l’esperienza di navigazione.
            <br />
            <br />
            La registrazione della sessione di navigazione avviene senza
            l’installazione di cookie o codici web, ma tramite gli eventi
            registrati sul browser che utilizzi per visitare le nostre pagine
            web: in questo modo il tuo browser invia dei dati, in forma anonima,
            dal client verso i nostri server, senza identificarti in modo
            diretto. Grazie a questo software i tuoi dati di navigazione non
            vengono collegati alla tua identità e ai tuoi dati personali. I dati
            raccolti grazie al nostro software di analisi vengono utilizzati
            soltanto da noi e da professionisti esterni che ci supportano nelle
            attività di marketing e di pubblicità online.
          </Text>
          <br />
          <br />
          {TableEntries.map((entry, index) => (
            <div key={index}>
              <Text
                mt={[3, 4]}
                marginBottom={3}
                fontSize={["13px", "20px"]}
                fontWeight="700"
              >
                {entry.title}
              </Text>
              <div style={{ maxWidth: "100%", overflowY: "auto" }}>
                <table
                  style={{ minWidth: "400px", fontSize: "13px" }}
                  class="euiTable--responsive"
                >
                  <thead>
                    <tr>
                      <th style={{ textAlign: "justify" }}>Cookie</th>
                      <th style={{ textAlign: "justify" }}>Vendor</th>
                      <th style={{ textAlign: "justify" }}>Duration</th>
                      <th style={{ textAlign: "justify" }}>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entry.items.map((cookie, i) => (
                      <tr
                        key={i}
                        style={{
                          paddingTop: "10px",
                          paddingLeft: "10px",
                          background: i % 2 === 0 ? "#f2f2f2" : "",
                        }}
                      >
                        <td style={{ padding: "10px 0" }}>{cookie.cookie}</td>
                        <td>{cookie.vendor}</td>
                        <td>{cookie.duration}</td>
                        <td>{cookie.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Autorizzazione alla cessione delle preferenze raccolte
          </Text>{" "}
          <Text mt={[3, 4]} fontSize={["13px"]}>
            Se navighi su questo sito e sulle pagine web create da Semplice
            Media, potremmo condividere le tue preferenze di navigazione con
            partner commerciali terzi. Le tue preferenze di navigazione sono
            raccolte in forma anonima e non ci permettono di identificarti in
            modo diretto. Puoi revocare il tuo consenso alla cessione delle tue
            preferenze di navigazione cliccando sul tasto che permette di
            selezionare le impostazioni dei cookie.
          </Text>
          <Text mt={[3, 4]} fontSize={["16px", "20px"]} fontWeight="700">
            Siti Web e servizi di terze parti
          </Text>{" "}
          <Text mt={[3, 4]} fontSize={["13px"]}>
            Il Sito potrebbe contenere collegamenti ad altri siti Web che
            dispongono di una propria informativa sulla privacy diversa da
            quella adottata da Semplice Media e di cui Semplice Media non potrà
            rispondere. La presente versione dell’informativa sul trattamento
            dei dati personali è stata aggiornata il giorno 03.09.2024.
          </Text>
        </Box>
      </Flex>
    </Layout>
  )
}

export default PrivacyPolicy
